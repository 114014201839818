import * as React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  List,
  ListProps,
  ReferenceInput,
  TextField,
  TextInput,
} from "react-admin";
import BooleanColorField from "../components/BooleanColorField";
import IdField from "../components/IdField";
import LinkReferenceField from "../components/LinkReferenceField";

export default function ClinicList(props: ListProps): JSX.Element {
  return (
    <List
      {...props}
      hasCreate={false}
      filters={[
        <TextInput label="Search" source="search" alwaysOn />,
        <ReferenceInput
          source="organization.id"
          reference="organizations"
          alwaysOn
        >
          <AutocompleteInput
            label="Organization"
            optionValue="id"
            optionText="name"
            sx={{
              minWidth: 191,
            }}
          />
        </ReferenceInput>,
      ]}
      perPage={25}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" sortable={false} />
        <IdField source="id" sortable={false} copyable={false} />
        <IdField source="customerNumber" sortable={false} copyable={false} />
        <LinkReferenceField
          sortable={false}
          source="organization.id"
          reference="organizations"
          link="show"
        >
          <TextField source="organization.name" />
        </LinkReferenceField>
        <BooleanColorField
          source="diPracticeId"
          label="Analytics ID"
          looseValue
        />
        <BooleanColorField
          source="mdPracticeId"
          label="Engagement ID"
          looseValue
        />
        <BooleanColorField source="lmOfficeId" label="LocalMed ID" looseValue />
        <IdField source="crmAccountId" sortable={false} copyable={false} />
        <DateField source="createdAt" sortable={false} />
      </Datagrid>
    </List>
  );
}
