import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  HttpError,
  RaRecord,
} from "react-admin";
import baseDataProvider from "../dataProvider/baseDataProvider";
import { gql, postGraphql } from "../dataProvider/utils";

interface WonAccountGetListResultNodeParent {
  id: string;
  name: string;
}

interface WonAccountGetListResultNode {
  id: string;
  name: string;
  activeProducts: string[];
  isSnapshot: boolean | null;
  clinicId: string | null;
  practiceManagementSystem: string | null;
  parent: WonAccountGetListResultNodeParent | null;
}

export interface WonAccountGetListResultItem
  extends WonAccountGetListResultNode {
  hasActiveProducts: boolean;
}

interface WonAccountParent {
  id: string;
  name: string;
  organizationId: string | null;
}

interface WonAccountPrimaryContact {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
}

export interface WonAccountGetOneResult {
  id: string;
  name: string;
  clinicId: string | null;
  parent: WonAccountParent | null;
  billingStreet: string | null;
  billingCity: string | null;
  billingState: string | null;
  billingPostalCode: string | null;
  billingCountry: string | null;
  billingLatitude: string | null;
  billingLongitude: string | null;
  email: string | null;
  phone: string | null;
  website: string | null;
  practiceManagementSystem: string | null;
  primaryContact: WonAccountPrimaryContact | null;
}

const wonAccountsDataProvider: DataProvider = {
  ...baseDataProvider,

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    const { filter } = params;
    const response = await postGraphql(
      gql`
        query WonAccounts($search: String, $afterCursor: ID, $size: Int = 200) {
          salesforceAccountProspects(
            search: $search
            afterCursor: $afterCursor
            size: $size
          ) {
            pageInfo {
              totalCount
              nextCursor
            }
            nodes {
              id
              name
              activeProducts
              isSnapshot
              clinicId
              practiceManagementSystem
              parent {
                id
                name
                organizationId
              }
            }
          }
        }
      `,
      {
        afterCursor: filter.afterCursor,
        search: filter.q || filter.search,
        size: filter.size,
      }
    );
    return {
      data: response.json.data.salesforceAccountProspects.nodes.map(
        (a: WonAccountGetListResultNode) => ({
          ...a,
          hasActiveProducts: a.activeProducts?.length > 0,
          hasClinic: a.clinicId != null && a.clinicId !== "",
        })
      ),
      total: response.json.data.salesforceAccountProspects.pageInfo.totalCount,
    };
  },

  async getOne<RecordType extends RaRecord = any>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const response = await postGraphql(
      gql`
        query WonAccountsGetOne($id: ID!) {
          salesforceAccount(id: $id) {
            id
            name
            organizationId
            clinicId
            parent {
              id
              name
              organizationId
            }
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingLatitude
            billingLongitude
            phone
            email
            website
            practiceManagementSystem
            primaryContact {
              firstName
              lastName
            }
          }
        }
      `,
      { id: params.id }
    );
    const { salesforceAccount } = response.json.data;
    if (!salesforceAccount) {
      throw new HttpError("Salesforce propects not found", 404);
    }

    return { data: salesforceAccount };
  },
};

export default wonAccountsDataProvider;
