import { useRef } from "react";
import { email, number, regex, useDataProvider } from "react-admin";
import { GuidGetOneResult } from "../guid/guidDataProvider";
import { PMSType, STATESMappings } from "./type";

function normalizePMSName(pms: string): string {
  return pms.toLocaleLowerCase().replace(" ", "").trim();
}

export function getPMSType(
  pms: string | null | undefined
): PMSType | undefined {
  if (pms == null) return undefined;

  const normalizedPMS = normalizePMSName(pms);
  const found = Object.entries(PMSType).find(
    ([key]) => normalizePMSName(key) === normalizedPMS
  );
  return found ? found[1] : undefined;
}

export const createGUID = (name: string | null) => {
  return name
    ?.toLocaleLowerCase()
    .trim()
    .replace(/[^A-Za-z0-9\s]/g, "")
    .replace(/\s+/g, "-");
};

export const parsePhoneNumber = (phone?: string | null): string => {
  if (!phone) {
    return "";
  }
  return phone.replace(/[+\-()\s+]/g, "").slice(-10);
};

export const formatPhoneNumber = (phoneNumberString: string): string | null => {
  const parsed = parsePhoneNumber(phoneNumberString);
  const cleaned = `${parsed}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export const parseUrl = (value: string | null): string | null => {
  if (!value) return null;
  if (value.match(/^https?:\/\//i)) return value;
  return `https://${value}`;
};

export const validatePhone = [
  number(),
  regex(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/),
];
export const validateEmail = email();
export const validateZipCode = [
  number(),
  regex(/^\d{5}$/, "Must be a valid Zip Code"),
];
export const validateUrl = regex(
  /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-./]+[a-zA-Z0-9]\.[^\s]{2,}$/,
  "Must be a valid Url"
);
export const validateUuid = regex(
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
  "Must be a valid ID format."
);

export const PMS_LIST = Object.entries(PMSType).map(([key, value]) => ({
  label: key,
  value,
}));

export function useValidateGuid() {
  const dataProvider = useDataProvider();
  const cacheRef = useRef<Record<string, boolean>>({});
  const cache = cacheRef.current;
  return async function validateGuid(
    value: unknown
  ): Promise<string | undefined> {
    if (value == null) return undefined;
    if (typeof value !== "string") return "Must be a string";

    if (cache[value] == null) {
      const response = await dataProvider.getOne<GuidGetOneResult>("guid", {
        id: value,
      });
      cache[value] = response.data.isAvailable;
    }
    return cache[value] ? undefined : "This GUID is not available";
  };
}

export const parseState = (state: unknown): string | null => {
  if (typeof state !== "string") return null;

  if (
    Object.values<string>(STATESMappings).includes(state?.trim().toUpperCase())
  ) {
    return state;
  }

  // eslint-disable-next-line @typescript-eslint/dot-notation
  return (
    STATESMappings[
      state?.trim().toLowerCase() as keyof typeof STATESMappings
    ] ?? null
  );
};
