import { CreateParams, CreateResult, DataProvider } from "react-admin";
import baseDataProvider from "../dataProvider/baseDataProvider";
import { gql, postGraphqlMutation } from "../dataProvider/utils";

export interface CreateParentSalesforceAccountResult {
  id: string;
  name: string;
  organizationId: string | null;
}

const parentSalesforceAccountDataProvider: DataProvider = {
  ...baseDataProvider,
  async create(
    resource: string,
    params: CreateParams
  ): Promise<CreateResult<any>> {
    const { data } = params;
    const response = await postGraphqlMutation(
      gql`
        mutation CreateParentAccount($id: ID!) {
          createParentSalesforceAccount(id: $id) {
            status
            errors {
              key
              details {
                code
                message
              }
            }
            payload {
              id
              name
              organizationId
            }
          }
        }
      `,
      {
        id: data.id,
      },
      "createParentSalesforceAccount"
    );
    return {
      data: {
        ...response.json.data.createParentSalesforceAccount.payload,
      },
    };
  },
};

export default parentSalesforceAccountDataProvider;
