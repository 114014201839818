import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import baseDataProvider from "../dataProvider/baseDataProvider";
import {
  gql,
  normalizeGuid,
  postGraphql,
  postGraphqlMutation,
} from "../dataProvider/utils";

export interface CreateOrganizationResult {
  id: string;
  name: string;
  crmAccountId: string;
}

const organizationsDataProvider: DataProvider = {
  ...baseDataProvider,
  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    const { pagination, filter } = params;
    const response = await postGraphql(
      gql`
        query UAOrganizationGetList(
          $search: String
          $clinicCountGreaterThan: Int
          $clinicCountLessThan: Int
          $page: Int
          $perPage: Int
        ) {
          organizations(
            search: $search
            clinicCountGreaterThan: $clinicCountGreaterThan
            clinicCountLessThan: $clinicCountLessThan
            page: $page
            perPage: $perPage
          ) {
            pageInfo {
              totalCount
            }
            nodes {
              id
              customerNumber
              name
              lmPracticeId
              mdDentalGroupId
              crmAccountId
              createdAt
            }
          }
        }
      `,
      {
        page: pagination.page,
        perPage: pagination.perPage,
        search: filter.q || filter.search,
        clinicCountGreaterThan: filter.clinicCountGreaterThan,
        clinicCountLessThan: filter.clinicCountLessThan,
      }
    );
    return {
      data: response.json.data.organizations.nodes,
      total: response.json.data.organizations.pageInfo.totalCount,
    };
  },

  async getOne(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<any>> {
    const response = await postGraphql(
      gql`
        query UAOrganizationGetOne($id: ID!) {
          organization(id: $id) {
            id
            customerNumber
            name
            lmPracticeId
            lmPractice {
              id
              name
            }
            mdDentalGroupId
            crmAccountId
            createdAt
            createdBy {
              id
              username
            }
            updatedAt
            updatedBy {
              id
              username
            }
            clinics {
              pageInfo {
                totalCount
              }
              nodes {
                id
                name
                customerNumber
              }
            }
          }
        }
      `,
      { id: params.id }
    );
    const { organization } = response.json.data;
    normalizeGuid(organization, "createdBy.id");
    normalizeGuid(organization, "updatedBy.id");
    return { data: organization };
  },

  async create(
    resource: string,
    params: CreateParams
  ): Promise<CreateResult<any>> {
    const { data } = params;
    const response = await postGraphqlMutation(
      gql`
        mutation UAOrganizationCreate($input: CreateOrganizationInput!) {
          createOrganization(input: $input) {
            status
            errors {
              key
              details {
                code
                message
              }
            }
            payload {
              id
              name
            }
          }
        }
      `,
      {
        input: {
          name: data.name,
          crmAccountId: data.crmAccountId,
        },
      },
      "createOrganization"
    );
    return {
      data: { ...data, ...response.json.data.createOrganization.payload },
    };
  },

  async update(
    resource: string,
    params: UpdateParams
  ): Promise<UpdateResult<any>> {
    const { id, data } = params;
    const response = await postGraphqlMutation(
      gql`
        mutation UAOrganizationUpdate(
          $id: ID!
          $input: UpdateOrganizationInput!
        ) {
          updateOrganization(id: $id, input: $input) {
            status
            errors {
              key
              details {
                code
                message
              }
            }
            payload {
              id
              name
            }
          }
        }
      `,
      {
        id,
        input: {
          name: data.name,
        },
      },
      "updateOrganization"
    );
    return {
      data: { ...data, ...response.json.data.updateOrganization.payload },
    };
  },
};

export default organizationsDataProvider;
