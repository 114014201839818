import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Collapse,
  ListItemIcon,
  ListItemText,
  MenuItem,
  styled,
  SvgIconProps,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import { useSidebarState } from "react-admin";

interface ExpandMoreProps extends SvgIconProps {
  expand: boolean;
  open: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand, open }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: open ? "auto" : undefined,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface CollapsibleMenuItemProps {
  children: ReactNode;
  primaryText: string;
}

export default function CollapsibleMenuItem({
  primaryText,
  children,
}: CollapsibleMenuItemProps) {
  const [expanded, setExpanded] = useState(false);
  const [open] = useSidebarState();

  const menuItem = (
    <MenuItem
      onClick={() => setExpanded((current) => !current)}
      sx={{ marginTop: 2 }}
    >
      {open && (
        <ListItemText>
          <Typography variant="caption">{primaryText}</Typography>
        </ListItemText>
      )}
      <ListItemIcon>
        <ExpandMore expand={expanded} open={open} />
      </ListItemIcon>
    </MenuItem>
  );

  return (
    <>
      {open ? (
        menuItem
      ) : (
        <Tooltip title={primaryText} placement="right">
          {menuItem}
        </Tooltip>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
}
