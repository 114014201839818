import { Alert, AlertTitle, Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import {
  EditProps,
  FormDataConsumer,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
  required,
  useCreatePath,
} from "react-admin";
import CheckboxInput from "../components/CheckboxInput";
import CustomEdit from "../components/CustomEdit";
import { validateUuid } from "./utils";

function ClinicDiPracticeMappingEditActions() {
  return (
    <TopToolbar>
      <ShowButton resource="clinics" />
    </TopToolbar>
  );
}

export default function ClinicDiPracticeMappingEdit(
  props: EditProps
): JSX.Element {
  const createPath = useCreatePath();
  return (
    <CustomEdit
      {...props}
      resource="clinicDiPracticeMappings"
      redirect={(_, id) => {
        const path = createPath({ resource: "clinics", id, type: "show" });
        return { pathname: path };
      }}
      actions={<ClinicDiPracticeMappingEditActions />}
    >
      <SimpleForm
        mode="onChange"
        reValidateMode="onChange"
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <Box
          display="flex"
          sx={(theme) => ({
            alignItems: "flex-start",
            flexDirection: "column",
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
              width: "100%",
              maxWidth: 1000,
            },
          })}
        >
          <Alert
            severity="warning"
            sx={(theme) => ({
              marginBottom: 3,
              [theme.breakpoints.up("md")]: {
                marginBottom: 0,
                marginLeft: 3,
                order: 2,
                flex: 1,
              },
            })}
          >
            <AlertTitle>Proceed with Caution</AlertTitle>
            <Stack spacing={1} component="ul">
              <Typography component="li">
                This will disconnect the original Analytics Clinic from this
                Clinic.
              </Typography>
              <FormDataConsumer>
                {({ formData }) =>
                  formData.remove ? (
                    <Typography component="li">
                      This will create a new Analytics Clinic with a new
                      Customer Number.
                    </Typography>
                  ) : (
                    <Typography component="li">
                      The Analytics Clinic’s practice info and name will be
                      updated to match this Clinic. Make sure the info is
                      accurate before proceeding.
                    </Typography>
                  )
                }
              </FormDataConsumer>
            </Stack>
          </Alert>
          <Stack
            spacing={1}
            width="100%"
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                flex: 1,
              },
            })}
          >
            <CheckboxInput
              source="remove"
              label="Remove Mapping & Generate New Analytics Clinic"
            />
            <FormDataConsumer>
              {({ formData }) =>
                !formData.remove && (
                  <TextInput
                    source="diPracticeId"
                    validate={[required(), validateUuid]}
                  />
                )
              }
            </FormDataConsumer>
          </Stack>
        </Box>
      </SimpleForm>
    </CustomEdit>
  );
}
