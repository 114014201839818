import {
  DataProvider,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import baseDataProvider from "../dataProvider/baseDataProvider";
import { gql, postGraphql, postGraphqlMutation } from "../dataProvider/utils";

const clinicPmsTypesDataProvider: DataProvider = {
  ...baseDataProvider,
  async getOne(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<any>> {
    const response = await postGraphql(
      gql`
        query UAClinicPMSTypesGetOne($id: ID!) {
          clinic(id: $id) {
            id
            name
            practiceManagementSystemType
          }
        }
      `,
      { id: params.id }
    );
    const { clinic } = response.json.data;
    return { data: clinic };
  },

  async update(
    resource: string,
    params: UpdateParams
  ): Promise<UpdateResult<any>> {
    const { id, data } = params;
    const response = await postGraphqlMutation(
      gql`
        mutation UAClinicPracticeManagementSystemTypeUpdate(
          $id: ID!
          $input: UpdateClinicPracticeManagementSystemTypeInput!
        ) {
          mutation: updateClinicPracticeManagementSystemType(
            id: $id
            input: $input
          ) {
            status
            errors {
              key
              details {
                code
                message
              }
            }
            payload {
              id
              name
            }
          }
        }
      `,
      {
        id,
        input: {
          practiceManagementSystemType: data.practiceManagementSystemType,
        },
      },
      "mutation"
    );
    return {
      data: { ...data, ...response.json.data.mutation.payload },
    };
  },
};

export default clinicPmsTypesDataProvider;
