import * as React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import AsideContainer from "../components/AsideContainer";
import BooleanColorField from "../components/BooleanColorField";
import CustomShow from "../components/CustomShow";
import IdField from "../components/IdField";
import ReferenceListField from "../components/ReferenceListField";
import TabLabel from "../components/TabLabel";

function UserAside() {
  return (
    <AsideContainer>
      <SimpleShowLayout spacing={2}>
        <IdField source="id" />
        <DateField source="createdAt" showTime emptyText="–" />
        <DateField source="updatedAt" showTime emptyText="–" />
      </SimpleShowLayout>
    </AsideContainer>
  );
}

export default function UserShow(props: ShowProps) {
  return (
    <CustomShow {...props} aside={<UserAside />}>
      <TabbedShowLayout spacing={2}>
        <Tab label="Summary">
          <IdField source="username" />
          <TextField source="givenName" />
          <TextField source="familyName" />
          <TextField source="phoneNumber" emptyText="–" />
          <ChipField source="status" />
          <ChipField source="type" />
        </Tab>
        <Tab
          label={
            <TabLabel label="Feature Flags" countSource="featureFlags.length" />
          }
          path="featureFlags"
        >
          <ArrayField source="featureFlags" label={false}>
            <Datagrid
              resource="diPracticeGrants"
              bulkActionButtons={false}
              rowClick="show"
            >
              <IdField source="grantId" />
              <TextField source="name" />
              {/* <RemovePracticeGrantButton /> */}
            </Datagrid>
          </ArrayField>
          {/* <FunctionField
          render={(record: any) => (
            <Button
              component={Link}
              to={`${addFeatureFlagPath}?practiceId=${record.id}`}
              state={{ record: { practiceId: record.id } }}
              size="small"
              startIcon={<Add />}
            >
              Add Feature Flag
            </Button>
          )}
        /> */}
        </Tab>
        <Tab
          label={
            <TabLabel label="DI Practices" countSource="practices.length" />
          }
          path="diPractices"
        >
          <ReferenceListField
            source="practices"
            reference="diPractices"
            label={false}
          >
            <TextField source="name" />
            <IdField source="customerNumber" />
            <BooleanColorField source="isActive" />
            <BooleanColorField source="isPracticeAdmin" label="Admin" />
          </ReferenceListField>
        </Tab>
      </TabbedShowLayout>
    </CustomShow>
  );
}
