import { Box } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import DiLoadingIndicator from "../components/DiLoadingIndicator";
import { handleRedirect } from "./idp";

export default function AuthorizedPage() {
  const navigate = useNavigate();
  React.useEffect(() => {
    handleRedirect(navigate);
  }, [navigate]);
  return (
    // This page is flashed loading redirecting to the IDP Server for login,
    // so we show a loading indicator to make that less jarring.
    <Box
      sx={{
        position: "absolute",
        inset: "0",
        backgroundColor: "#f7f7f7",
      }}
    >
      <DiLoadingIndicator />
    </Box>
  );
}
