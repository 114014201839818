import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import * as React from "react";
import {
  ChipField,
  DateField,
  FunctionField,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useCreatePath,
} from "react-admin";
import { Link } from "react-router-dom";
import AsideContainer from "../components/AsideContainer";
import CustomShow from "../components/CustomShow";
import IdField from "../components/IdField";
import LinkReferenceField from "../components/LinkReferenceField";
import ReferenceCollectionField from "../components/ReferenceCollectionField";
import TabLabel from "../components/TabLabel";

function DIGrantAside() {
  return (
    <AsideContainer>
      <SimpleShowLayout spacing={2}>
        <IdField source="id" />
        <DateField source="createdAt" showTime />
        <LinkReferenceField source="createdBy.id" reference="users" link="show">
          <TextField source="createdBy.username" emptyText="–" />
        </LinkReferenceField>
      </SimpleShowLayout>
    </AsideContainer>
  );
}

export default function DIGrantShow(props: ShowProps): JSX.Element {
  const createPath = useCreatePath();
  return (
    <CustomShow {...props} aside={<DIGrantAside />}>
      <TabbedShowLayout spacing={2}>
        <Tab label="Summary">
          <TextField source="name" />
          <ChipField source="type" emptyText="–" />
          <TextField
            source="practiceMemberships.pageInfo.totalCount"
            label="Permission Count"
            emptyText="0"
          />
        </Tab>
        <Tab
          label={
            <TabLabel
              label="Practices"
              countSource="practices.pageInfo.totalCount"
            />
          }
          path="practices"
        >
          <ReferenceCollectionField
            source="practices"
            reference="diPractices"
            label={false}
          >
            <TextField source="name" />
            <IdField source="customerNumber" />
            <DateField source="createdAt" />
          </ReferenceCollectionField>
          <FunctionField
            render={(record: any) => (
              <Button
                component={Link}
                to={createPath({
                  resource: "diPracticeGrants",
                  type: "create",
                })}
                state={{ record: { grantId: record.id } }}
                size="small"
                startIcon={<Add />}
              >
                Add Practice
              </Button>
            )}
          />
        </Tab>
        <Tab
          label={
            <TabLabel label="Users" countSource="users.pageInfo.totalCount" />
          }
          path="users"
        >
          <ReferenceCollectionField
            source="users"
            reference="users"
            label={false}
          >
            <TextField source="username" />
            <TextField source="givenName" />
            <TextField source="familyName" />
            <DateField source="createdAt" />
          </ReferenceCollectionField>
        </Tab>
      </TabbedShowLayout>
    </CustomShow>
  );
}
