import { Divider, Grid, Typography } from "@mui/material";
import * as React from "react";
import { Labeled, ShowProps, SimpleShowLayout, TextField } from "react-admin";
import AsideContainer from "../components/AsideContainer";
import BooleanColorField from "../components/BooleanColorField";
import CustomShow from "../components/CustomShow";
import IdField from "../components/IdField";
import LinkReferenceField from "../components/LinkReferenceField";

function LmOfficeAside() {
  return (
    <AsideContainer>
      <SimpleShowLayout spacing={2}>
        <IdField source="id" />
        <Typography variant="subtitle2">Features</Typography>
        <BooleanColorField source="announcementEnabled" />
        <BooleanColorField source="appointmentRemindersEnabled" />
        <BooleanColorField source="appointmentReschedulingEnabled" />
        <BooleanColorField source="insuranceCoverageEnabled" />
        <BooleanColorField source="onlineSchedulingEnabled" />
        <BooleanColorField source="patientScreenerEnabled" />
      </SimpleShowLayout>
    </AsideContainer>
  );
}

export default function LmOfficeShow(props: ShowProps): JSX.Element {
  return (
    <CustomShow {...props} aside={<LmOfficeAside />}>
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Labeled source="name">
              <TextField source="name" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled source="messagingName">
              <TextField source="messagingName" emptyText="–" />
            </Labeled>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Relations</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LinkReferenceField
              source="lmPractice.id"
              reference="lmPractices"
              link="show"
            >
              <Labeled source="lmPractice">
                <TextField source="lmPractice.name" />
              </Labeled>
            </LinkReferenceField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LinkReferenceField
              source="clinic.id"
              reference="clinics"
              link="show"
            >
              <Labeled source="clinic">
                <TextField source="clinic.name" />
              </Labeled>
            </LinkReferenceField>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Address</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled source="address1">
              <TextField source="address1" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled source="address2">
              <TextField source="address2" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled source="city">
              <TextField source="city" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled source="stateCode">
              <TextField source="stateCode" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled source="postalCode">
              <TextField source="postalCode" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled source="countryCode">
              <TextField source="countryCode" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Labeled source="timeZoneCode">
              <TextField source="timeZoneCode" />
            </Labeled>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </CustomShow>
  );
}
