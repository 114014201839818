import { ClinicCustomerType } from "./type";

export const timeZoneCodes = [
  { id: "Pacific/Honolulu" },
  { id: "America/Juneau" },
  { id: "America/Los_Angeles" },
  { id: "America/Vancouver" },
  { id: "America/Phoenix" },
  { id: "America/Denver" },
  { id: "America/Edmonton" },
  { id: "America/Chicago" },
  { id: "America/Regina" },
  { id: "America/Winnipeg" },
  { id: "America/New_York" },
  { id: "America/Indiana/Indianapolis" },
  { id: "America/Toronto" },
  { id: "America/Jamaica" },
  { id: "America/Halifax" },
  { id: "America/Puerto_Rico" },
];

export const US_STATES = [
  { id: "AK" },
  { id: "AL" },
  { id: "AR" },
  { id: "AS" },
  { id: "AZ" },
  { id: "CA" },
  { id: "CO" },
  { id: "CT" },
  { id: "DC" },
  { id: "DE" },
  { id: "FL" },
  { id: "GA" },
  { id: "GU" },
  { id: "HI" },
  { id: "IA" },
  { id: "ID" },
  { id: "IL" },
  { id: "IN" },
  { id: "KS" },
  { id: "KY" },
  { id: "LA" },
  { id: "MA" },
  { id: "MD" },
  { id: "ME" },
  { id: "MI" },
  { id: "MN" },
  { id: "MO" },
  { id: "MP" },
  { id: "MS" },
  { id: "MT" },
  { id: "NC" },
  { id: "ND" },
  { id: "NE" },
  { id: "NH" },
  { id: "NJ" },
  { id: "NM" },
  { id: "NV" },
  { id: "NY" },
  { id: "OH" },
  { id: "OK" },
  { id: "OR" },
  { id: "PA" },
  { id: "PR" },
  { id: "RI" },
  { id: "SC" },
  { id: "SD" },
  { id: "TN" },
  { id: "TX" },
  { id: "UT" },
  { id: "VA" },
  { id: "VI" },
  { id: "VT" },
  { id: "WA" },
  { id: "WI" },
  { id: "WV" },
  { id: "WY" },
];

export const clinicCustomerTypeOptions: {
  id: ClinicCustomerType;
  name: string;
}[] = [
  { id: ClinicCustomerType.CUSTOMER, name: "Customer" },
  { id: ClinicCustomerType.DEMO, name: "Demo" },
  { id: ClinicCustomerType.SNAPSHOT, name: "Snapshot" },
];
