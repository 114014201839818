interface PracticeGrant {
  id: string;
  practiceId?: string;
  practice?: {
    id: string;
  };
}

interface NormalizedPracticeGrant extends PracticeGrant {
  grantId: string;
}

const idSeparator = "--";

export function getPracticeGrantIds(id: string): [string, string] {
  const parts = id.split(idSeparator, 2);
  if (parts.length !== 2) {
    throw new Error(`Could not split PracticeGrant id: "${id}"`);
  }
  return [parts[0], parts[1]];
}

// React Admin requires a single ID, so this combines the practice and grant
// IDs into a single ID.
export function normalizePracticeGrantId(
  practiceGrant: PracticeGrant
): NormalizedPracticeGrant {
  const grantId = practiceGrant.id;
  const practiceId = practiceGrant.practiceId || practiceGrant.practice?.id;
  const normalized = practiceGrant as unknown as NormalizedPracticeGrant;
  normalized.id = [practiceId, practiceGrant.id].join(idSeparator);
  normalized.grantId = grantId;
  return normalized;
}
