import * as React from "react";
import {
  Create,
  CreateProps,
  useGetRecordRepresentation,
  useNotify,
  useRedirect,
  useResourceContext,
  useTranslate,
} from "react-admin";

interface CustomCreateProps extends CreateProps {
  children: React.ReactNode;
}

export default function CustomCreate({
  children,
  redirect: redirectTo = "show",
  ...props
}: CustomCreateProps) {
  const resource = useResourceContext(props);
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const getRecordRepresentation = useGetRecordRepresentation(resource);

  function safeTranslate(
    key: string,
    options: Parameters<typeof translate>[1]
  ): string | null {
    const result = translate(key, options);
    return result === key ? null : result;
  }

  return (
    <Create
      {...props}
      mutationOptions={{
        ...props.mutationOptions,
        onSuccess: (record: any) => {
          const name = getRecordRepresentation(record);
          const message =
            safeTranslate(`resources.${resource}.notification.created`, {
              name,
              smart_count: 1,
            }) ??
            safeTranslate("notification.created", { name, smart_count: 1 });
          notify(message);
          redirect(redirectTo, resource, record?.id, record);
        },
      }}
    >
      {children}
    </Create>
  );
}
