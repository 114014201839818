import { Chip, Stack } from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import { useRecordContext } from "react-admin";

interface TabLabelProps {
  label: string;
  countSource?: string | undefined;
}

export default function TabLabel({ label, countSource }: TabLabelProps) {
  const record = useRecordContext();
  if (countSource == null) return <>{label}</>;
  const count = get(record, countSource);
  if (count == null) return <>{label}</>;
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <span>{label}</span>
      <Chip label={count} size="small" />
    </Stack>
  );
}
