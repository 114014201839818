import * as React from "react";
import {
  Edit,
  EditProps,
  useGetRecordRepresentation,
  useNotify,
  useRedirect,
  useRefresh,
  useResourceContext,
  useTranslate,
} from "react-admin";
import RecordTitle from "./RecordTitle";

interface CustomEditProps extends EditProps {
  children: React.ReactNode;
}

export default function CustomEdit({
  children,
  redirect: redirectTo = "show",
  ...props
}: CustomEditProps) {
  const resource = useResourceContext(props);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();
  const getRecordRepresentation = useGetRecordRepresentation(resource);

  function safeTranslate(
    key: string,
    options: Parameters<typeof translate>[1]
  ): string | null {
    const result = translate(key, options);
    return result === key ? null : result;
  }

  return (
    <Edit
      mutationMode="pessimistic"
      title={<RecordTitle />}
      {...props}
      mutationOptions={{
        ...props.mutationOptions,
        onSuccess: (record) => {
          const name = getRecordRepresentation(record);
          const message =
            safeTranslate(`resources.${resource}.notifications.updated`, {
              name,
              smart_count: 1,
            }) ??
            safeTranslate("notification.updated", { name, smart_count: 1 });
          notify(message);
          redirect(redirectTo, resource, record?.id, record);
          refresh();
        },
      }}
    >
      {children}
    </Edit>
  );
}
