import React from "react";
import { Datagrid, List, ListProps, TextField } from "react-admin";
import AppHealthPanel from "./AppHealthPanel";
import { AppHealth, ServiceInfo } from "./types";

const rowStyle = (record: any) => {
  const app: AppHealth = record;
  let color = "";
  if (app.summary.startsWith("Error:")) {
    color = "#ffe0e0"; // error (red)
  } else if (app.services.every((s: ServiceInfo) => s.result === true)) {
    color = "#ceffce"; // all services healthy (green)
  } else {
    color = "#ffe0a2"; // some services unhealthy (orange)
  }

  return {
    backgroundColor: color,
  };
};

const AppHealthList = (props: ListProps): JSX.Element => {
  return (
    <List {...props} pagination={false}>
      <Datagrid
        rowClick="expand"
        expand={<AppHealthPanel />}
        isRowExpandable={(app) => app.services.length > 0}
        sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "lightgray",
            fontWeight: "bold",
          },
        }}
        rowStyle={rowStyle}
      >
        <TextField source="appName" label="App Name" />
        <TextField source="summary" label="Summary" />
      </Datagrid>
    </List>
  );
};

export default AppHealthList;
