import { Alert, AlertTitle, Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import {
  EditProps,
  FormDataConsumer,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
  minValue,
  number,
  required,
  useCreatePath,
} from "react-admin";
import CheckboxInput from "../components/CheckboxInput";
import CustomEdit from "../components/CustomEdit";

function ClinicMdPracticeMappingEditActions() {
  return (
    <TopToolbar>
      <ShowButton resource="clinics" />
    </TopToolbar>
  );
}

export default function ClinicMdPracticeMappingEdit(
  props: EditProps
): JSX.Element {
  const createPath = useCreatePath();
  return (
    <CustomEdit
      {...props}
      resource="clinicMdPracticeMappings"
      redirect={(_, id) => {
        const path = createPath({ resource: "clinics", id, type: "show" });
        return { pathname: path };
      }}
      actions={<ClinicMdPracticeMappingEditActions />}
    >
      <SimpleForm
        mode="onChange"
        reValidateMode="onChange"
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <Box
          display="flex"
          sx={(theme) => ({
            alignItems: "flex-start",
            flexMdrection: "column",
            [theme.breakpoints.up("md")]: {
              flexMdrection: "row",
              width: "100%",
              maxWidth: 1000,
            },
          })}
        >
          <Alert
            severity="warning"
            sx={(theme) => ({
              marginBottom: 3,
              [theme.breakpoints.up("md")]: {
                marginBottom: 0,
                marginLeft: 3,
                order: 2,
                flex: 1,
              },
            })}
          >
            <AlertTitle>Proceed with Caution</AlertTitle>
            <Stack spacing={1} component="ul">
              <Typography component="li">
                This will disconnect the original Engagement Clinic from this
                Clinic. The original Engagement Clinic will retain it’s guid.
              </Typography>
              <FormDataConsumer>
                {({ formData }) =>
                  formData.remove ? (
                    <Typography component="li">
                      This will create a new Engagement Clinic with a new guid.
                    </Typography>
                  ) : (
                    <Typography component="li">
                      The Engagement Clinic’s practice info and name will be
                      updated to match this Clinic. Make sure the info is
                      accurate before proceeding.
                    </Typography>
                  )
                }
              </FormDataConsumer>
            </Stack>
          </Alert>
          <Stack
            spacing={1}
            width="100%"
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                flex: 1,
              },
            })}
          >
            <CheckboxInput
              source="remove"
              label="Remove Mapping & Generate New Engagement Clinic"
            />
            <FormDataConsumer>
              {({ formData }) =>
                !formData.remove && (
                  <TextInput
                    source="mdPracticeId"
                    validate={[
                      required(),
                      number("Must be a valid Engagement ID"),
                      minValue(1),
                    ]}
                  />
                )
              }
            </FormDataConsumer>
          </Stack>
        </Box>
      </SimpleForm>
    </CustomEdit>
  );
}
