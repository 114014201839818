import {
  DataProvider,
  GetOneParams,
  GetOneResult,
  RaRecord,
} from "react-admin";
import baseDataProvider from "../dataProvider/baseDataProvider";
import { gql, postGraphql } from "../dataProvider/utils";

export interface GuidGetOneResult extends RaRecord {
  isAvailable: boolean;
}

const guidDataProvider: DataProvider = {
  ...baseDataProvider,

  async getOne(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<any>> {
    const response = await postGraphql(
      gql`
        query GUIDAvailability($guid: String!) {
          guidAvailability(guid: $guid) {
            isAvailable
          }
        }
      `,
      { guid: params.id }
    );
    const { guidAvailability } = response.json.data;
    // To silence this warning from react-admin: "but the received data does
    // not have an 'id' key. The dataProvider is probably wrong for 'getOne'"
    guidAvailability.id = Date.now().toString();
    return { data: guidAvailability };
  },
};

export default guidDataProvider;
