import * as React from "react";
import {
  ChipField,
  Datagrid,
  List,
  ListProps,
  TextField,
  TextInput,
} from "react-admin";
import BooleanColorField from "../components/BooleanColorField";

export default function UserList(props: ListProps): JSX.Element {
  return (
    <List
      {...props}
      filters={[<TextInput label="Search" source="search" alwaysOn />]}
      perPage={25}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="username" sortable={false} />
        <TextField source="givenName" sortable={false} />
        <TextField source="familyName" sortable={false} />
        <BooleanColorField source="isActive" sortable={false} />
        <ChipField source="type" sortable={false} />
      </Datagrid>
    </List>
  );
}
