/* eslint-disable react/no-array-index-key */

import {
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { AppHealth, ServiceInfo } from "./types";

const AppHealthPanel = ({
  record: appHealth,
}: Record<string, AppHealth>): JSX.Element => {
  const formatServiceName = (serviceName: string) =>
    serviceName?.replaceAll(" ", "_");
  const formatValue = (
    columnName: string,
    value: string | boolean
  ): JSX.Element => {
    if (columnName === "result") {
      return value ? (
        <CheckIcon htmlColor="green" />
      ) : (
        <ErrorIcon htmlColor="red" />
      );
    }
    return <span>{value?.toString()}</span>;
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow key={`${appHealth.appName}-head`}>
            {Object.keys(appHealth.services[0]).map((columnName: string) => (
              <TableCell
                key={`${appHealth.appName}-head-${columnName}`}
                sx={{
                  backgroundColor: "lightgray",
                  fontWeight: "bold",
                }}
              >
                <b>{columnName}</b>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {appHealth.services.map((service: ServiceInfo, index: number) => (
            <TableRow
              key={`${appHealth.appName}-service${index}-${formatServiceName(
                service.serviceName
              )}`}
            >
              {Object.entries(service).map(
                ([columnName, value]: [string, string | boolean]) => (
                  <TableCell
                    key={`${
                      appHealth.appName
                    }-service${index}-${formatServiceName(
                      service.serviceName
                    )}-${columnName}`}
                  >
                    {formatValue(columnName, value)}
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AppHealthPanel;
