import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, IconButton, Typography } from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import {
  InjectedFieldProps,
  PublicFieldProps,
  useNotify,
  useRecordContext,
} from "react-admin";

export interface IdFieldProps extends InjectedFieldProps, PublicFieldProps {
  copyable?: boolean;
}

export default function IdField({
  source,
  emptyText,
  copyable,
  ...props
}: IdFieldProps) {
  const record = useRecordContext(props);
  const notify = useNotify();
  const value = get(record, source || "id");
  return (
    <Box>
      <Typography variant="code">{value || emptyText || "–"}</Typography>
      {copyable && value != null && value !== "" && (
        <>
          {" "}
          <IconButton
            arial-label="Copy"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              window.navigator.clipboard.writeText(value);
              notify("notification.copied", {
                type: "success",
                messageArgs: { content: JSON.stringify(value) },
              });
            }}
          >
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </>
      )}
    </Box>
  );
}

IdField.defaultProps = {
  copyable: true,
};
