import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  NumberInput,
  TextField,
  TextInput,
} from "react-admin";
import BooleanColorField from "../components/BooleanColorField";
import IdField from "../components/IdField";

export const organizationListFilterDefaultValues = {
  clinicCountGreaterThan: 1,
};

export default function OrganizationList(props: ListProps) {
  return (
    <List
      {...props}
      hasCreate={false}
      filters={[
        <TextInput label="Search" source="search" alwaysOn />,
        <NumberInput
          label="Clinic Count Greater Than"
          source="clinicCountGreaterThan"
        />,
        <NumberInput
          label="Clinic Count Less Than"
          source="clinicCountLessThan"
        />,
      ]}
      perPage={25}
      filterDefaultValues={organizationListFilterDefaultValues}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" sortable={false} />
        <IdField source="customerNumber" sortable={false} copyable={false} />
        <BooleanColorField
          source="mdDentalGroupId"
          label="Engagement ID"
          looseValue
        />
        <BooleanColorField
          source="lmPracticeId"
          label="LocalMed ID"
          looseValue
        />
        <DateField source="createdAt" sortable={false} />
      </Datagrid>
    </List>
  );
}
