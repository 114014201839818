import * as React from "react";
import { Show, ShowProps } from "react-admin";
import RecordTitle from "./RecordTitle";

interface CustomShowProps extends ShowProps {
  children: React.ReactNode;
}

export default function CustomShow({ children, ...props }: CustomShowProps) {
  return (
    <Show title={<RecordTitle />} {...props}>
      {children}
    </Show>
  );
}
