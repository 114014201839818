import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link, Stack } from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import { PublicFieldProps, useRecordContext, useTranslate } from "react-admin";
import {
  LM_API_BASE_URL,
  MD_ADMIN_BASE_URL,
  MD_DASHBOARD_BASE_URL,
  SALESFORCE_BASE_URL,
} from "../config";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExternalLinkReferenceFieldProps extends PublicFieldProps {
  source: string;
  reference: string;
  emptyContent?: React.ReactNode;
}

interface LinkGenerator {
  (id: string | number): string;
}

const linkGenerators: Record<string, LinkGenerator> = {
  mdPractices: (id: string | number) => `${MD_ADMIN_BASE_URL}/practices/${id}`,
  mdDashboardPractices: (id: string | number) =>
    `${MD_DASHBOARD_BASE_URL}/${id}`,
  lmPractices: (id: string | number) =>
    `${LM_API_BASE_URL}/provider-dashboard/practices/${id}`,
  lmOffices: (id: string | number) =>
    `${LM_API_BASE_URL}/clients/app/offices/${id}/edit/general`,
  salesforceAccounts: (id: string | number) =>
    `${SALESFORCE_BASE_URL}/lightning/r/Account/${id}/view`,
};

export default function ExternalLinkReferenceField({
  source,
  reference,
  emptyContent,
}: ExternalLinkReferenceFieldProps) {
  const translate = useTranslate();
  const record = useRecordContext();
  const linkGenerator = linkGenerators[reference];
  if (linkGenerator == null) {
    throw new Error(`External link generator not defined for '${reference}'`);
  }

  const id = get(record, source);
  if (id == null) {
    return typeof emptyContent === "undefined" ? (
      <span>–</span>
    ) : (
      <>{emptyContent}</>
    );
  }

  return (
    <Link
      href={linkGenerator(id)}
      underline="none"
      target="_blank"
      rel="noopener"
      variant="caption"
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <span>
          {translate(`message.external_link_references.${reference}`)}
        </span>
        <ExitToAppIcon fontSize="inherit" />
      </Stack>
    </Link>
  );
}
