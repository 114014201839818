import { sortBy } from "lodash";
import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  HttpError,
} from "react-admin";
import baseDataProvider from "../dataProvider/baseDataProvider";
import { gql, normalizeGuid, postGraphql } from "../dataProvider/utils";

const usersDataProvider: DataProvider = {
  ...baseDataProvider,

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    const { pagination, filter } = params;
    const response = await postGraphql(
      gql`
        query UADIUsersGetList($search: String, $page: Int, $perPage: Int) {
          diUsers(search: $search, page: $page, perPage: $perPage) {
            pageInfo {
              totalCount
            }
            nodes {
              id
              username
              givenName
              familyName
              isActive
              type
            }
          }
        }
      `,
      {
        page: pagination.page,
        perPage: pagination.perPage,
        search: filter.q || filter.search,
      }
    );
    return {
      data: response.json.data.diUsers.nodes,
      total: response.json.data.diUsers.pageInfo.totalCount,
    };
  },

  async getOne(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<any>> {
    const response = await postGraphql(
      gql`
        query UAUserGetOne($id: ID!) {
          diUser(id: $id) {
            id
            username
            givenName
            familyName
            phoneNumber
            status
            type
            createdAt
            updatedAt
            featureFlags {
              id
              # grantId
              # userId
              name
            }
            practiceMemberships(includeInactive: true) {
              isActive
              isPracticeAdmin
              practice {
                id
                name
                customerNumber
              }
            }
          }
        }
      `,
      { id: params.id }
    );
    const { diUser } = response.json.data;
    if (!diUser) {
      throw new HttpError("User not found", 404);
    }

    diUser.practices = sortBy(
      diUser.practiceMemberships.map((membership: any) => ({
        id: membership.practice.id,
        name: membership.practice.name,
        customerNumber: membership.practice.customerNumber,
        isActive: membership.isActive,
        isPracticeAdmin: membership.isPracticeAdmin,
      })),
      "name"
    );
    normalizeGuid(diUser, "id");
    return { data: diUser };
  },
};

export default usersDataProvider;
