import { Box, Stack } from "@mui/material";
import * as React from "react";
import {
  DateField,
  Labeled,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import AsideContainer from "../components/AsideContainer";
import CustomShow from "../components/CustomShow";
import ExternalLinkReferenceField from "../components/ExternalLinkReferenceField";
import IdField from "../components/IdField";
import LinkReferenceField from "../components/LinkReferenceField";
import ReferenceCollectionField from "../components/ReferenceCollectionField";
import TabLabel from "../components/TabLabel";

function OrganizationAside() {
  return (
    <AsideContainer>
      <SimpleShowLayout spacing={2}>
        <IdField source="id" />
        <IdField source="customerNumber" />
        <DateField source="createdAt" showTime />
        <LinkReferenceField source="createdBy.id" reference="users" link="show">
          <TextField source="createdBy.username" emptyText="–" />
        </LinkReferenceField>
        <DateField source="updatedAt" showTime />
        <LinkReferenceField source="updatedBy.id" reference="users" link="show">
          <TextField source="updatedBy.username" emptyText="–" />
        </LinkReferenceField>
      </SimpleShowLayout>
    </AsideContainer>
  );
}

export default function OrganizationShow(props: ShowProps) {
  return (
    <CustomShow {...props} aside={<OrganizationAside />}>
      <TabbedShowLayout spacing={2}>
        <Tab label="Summary">
          <TextField source="name" />
          <Labeled source="lmPractice">
            <Stack spacing={0.5}>
              <LinkReferenceField
                source="lmPractice.id"
                reference="lmPractices"
                link="show"
              >
                <TextField source="lmPractice.name" emptyText="–" />
              </LinkReferenceField>
              <ExternalLinkReferenceField
                reference="lmPractices"
                source="lmPractice.id"
              />
            </Stack>
          </Labeled>
          <IdField source="mdDentalGroupId" />
          <Labeled source="crmAccountId">
            <Box>
              <IdField source="crmAccountId" />
              <ExternalLinkReferenceField
                reference="salesforceAccounts"
                source="crmAccountId"
              />
            </Box>
          </Labeled>
        </Tab>
        <Tab
          label={
            <TabLabel
              label="Clinics"
              countSource="clinics.pageInfo.totalCount"
            />
          }
          path="clinics"
        >
          <ReferenceCollectionField
            source="clinics"
            reference="clinics"
            label={false}
            viewAllFilter={(record) => ({ organization: { id: record.id } })}
          >
            <TextField source="name" />
            <IdField source="customerNumber" />
          </ReferenceCollectionField>
        </Tab>
      </TabbedShowLayout>
    </CustomShow>
  );
}
