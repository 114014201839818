import { Alert, Stack, Typography } from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import {
  ArrayField,
  Datagrid,
  DatagridProps,
  Link,
  PublicFieldProps,
  RaRecord,
  ResourceContextProvider,
  useCreatePath,
  useRecordContext,
  useTranslate,
} from "react-admin";

export interface ReferenceCollectionFieldProps
  extends PublicFieldProps,
    Pick<DatagridProps, "rowClick"> {
  reference: string;
  viewAllFilter?: ((record: RaRecord) => any) | undefined;
  children: React.ReactNode;
}

interface Collection {
  pageInfo?: { totalCount: number | undefined } | undefined;
  nodes?: any[] | undefined;
}

interface PaginationInfoProps {
  resource: string;
  viewAllFilterValue: any;
  collection: Collection | undefined;
}

function PaginationInfo({
  resource,
  collection,
  viewAllFilterValue,
}: PaginationInfoProps) {
  const createPath = useCreatePath();
  const translate = useTranslate();
  if (collection == null) return null;

  const showingCount = collection.nodes?.length;
  const totalCount = collection.pageInfo?.totalCount;
  if (
    showingCount == null ||
    totalCount == null ||
    showingCount >= totalCount
  ) {
    return null;
  }

  const path = createPath({ resource, type: "list" });
  const params = new URLSearchParams({
    filter: JSON.stringify(viewAllFilterValue),
  });
  const link = `${path}?${params}`;
  return (
    <Stack direction="row" spacing={2} justifyContent="flex-end">
      <Typography variant="caption">
        {translate("message.reference_collection_field.showing", {
          showingCount,
          totalCount,
        })}
      </Typography>
      {viewAllFilterValue != null && (
        <Typography variant="caption">
          <Link to={link}>
            {translate("message.reference_collection_field.view_all")}
          </Link>
        </Typography>
      )}
    </Stack>
  );
}

export default function ReferenceCollectionField(
  props: ReferenceCollectionFieldProps
) {
  const { source, reference, viewAllFilter, rowClick, children } = props;
  const record = useRecordContext(props);
  const collection = source ? get(record, source) : undefined;
  const viewAllFilterValue = viewAllFilter ? viewAllFilter(record) : undefined;
  return (
    <ResourceContextProvider value={reference}>
      <Stack spacing={1}>
        <ArrayField source={`${source}.nodes`}>
          <Datagrid
            empty={<Alert severity="info">No records</Alert>}
            rowClick={rowClick ?? "show"}
            bulkActionButtons={false}
            sx={{
              border: "1px solid",
              borderColor: "grey.200",
              borderRadius: 1,
            }}
          >
            {children}
          </Datagrid>
        </ArrayField>
        <PaginationInfo
          resource={reference}
          collection={collection}
          viewAllFilterValue={viewAllFilterValue}
        />
      </Stack>
    </ResourceContextProvider>
  );
}

ReferenceCollectionField.defaultProps = {
  viewAllFilter: undefined,
};
