import { addSeconds, isBefore } from "date-fns";
import { NavigateFunction } from "react-router";
import { IDP_BASE_URL, OAUTH_CLIENT_ID, OAUTH_REDIRECT_URI } from "../config";
import { createCodeChallenge, createCodeVerifier } from "./codeChallenge";
import { Deferred } from "./utils";

async function redirectToLogin(): Promise<void> {
  const codeVerifier = createCodeVerifier();
  localStorage.setItem("codeVerifier", codeVerifier);
  const codeChallenge = await createCodeChallenge(codeVerifier);
  const urlParams = new URLSearchParams({
    client_id: OAUTH_CLIENT_ID,
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
    response_type: "code",
    prompt: "login",
    scope: "openid profile email dentalintel",
    redirect_uri: OAUTH_REDIRECT_URI,
  });
  window.location.href = `${IDP_BASE_URL}/connect/authorize?${urlParams}`;
}

async function getAccessTokenViaCode(
  code: string,
  codeVerifier: string
): Promise<any> {
  const formData = new URLSearchParams({
    client_id: OAUTH_CLIENT_ID,
    grant_type: "authorization_code",
    code,
    code_verifier: codeVerifier,
    redirect_uri: OAUTH_REDIRECT_URI,
  });
  const response = await fetch(`${IDP_BASE_URL}/connect/token`, {
    method: "POST",
    body: formData.toString(),
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
  return response.json();
}

// This is used to wait until after the Authorized redirect callback logic has
// been executed before we get access or id tokens.
const waitForRedirect = new Deferred<void>();
if (window.location.pathname !== "/authorized") {
  // We can immediately resolve on pages where we're not expecting
  // to handle the authorization redirect.
  waitForRedirect.resolve();
}

export async function handleRedirect(
  navigate?: NavigateFunction
): Promise<void> {
  const code = new URL(window.location.href).searchParams.get("code");
  const codeVerifier = localStorage.getItem("codeVerifier");
  if (code == null || codeVerifier == null) return;

  const response = await getAccessTokenViaCode(code, codeVerifier);
  localStorage.removeItem("codeVerifier");
  localStorage.setItem(
    "auth",
    JSON.stringify({
      access_token: response.access_token,
      id_token: response.id_token,
      scope: response.scope,
      token_type: response.token_type,
      expires: addSeconds(new Date(), response.expires_in - 30).valueOf(),
    })
  );
  waitForRedirect.resolve();
  navigate?.("/");
}

function isExpired(expires: number): boolean {
  return isBefore(expires, new Date());
}

export interface AuthResponse {
  id_token: string;
  access_token: string;
  expires: number;
  token_type: string;
  scope: string;
}

export async function getAuthResponse(): Promise<AuthResponse | undefined> {
  await waitForRedirect.promise;
  const storedAuthResponse = localStorage.getItem("auth");
  if (!storedAuthResponse) return undefined;

  return JSON.parse(storedAuthResponse);
}

export async function getAccessToken(): Promise<string | undefined> {
  const authResponse = await getAuthResponse();
  if (!authResponse) return undefined;

  if (isExpired(authResponse.expires)) return undefined;
  return authResponse.access_token;
}

interface AccessTokenPayload {
  sub: string;
  SuperAdmin?: string;
}

export async function getAccessTokenPayload(): Promise<
  AccessTokenPayload | undefined
> {
  const accessToken = await getAccessToken();
  if (!accessToken) return undefined;

  const [, accessTokenPayload] = accessToken.split(".");
  try {
    return JSON.parse(atob(accessTokenPayload));
  } catch (err) {
    return undefined;
  }
}

interface IdTokenPayload {
  sub: string;
  given_name: string;
  family_name: string;
}

export async function getIdTokenClaims(): Promise<IdTokenPayload | undefined> {
  const authResponse = await getAuthResponse();
  if (!authResponse) return undefined;

  const [, idTokenPayload] = authResponse.id_token.split(".");
  try {
    return JSON.parse(atob(idTokenPayload));
  } catch (err) {
    return undefined;
  }
}

export async function isAuthenticated(): Promise<boolean> {
  const accessTokenPayload = await getAccessTokenPayload();
  // For now only SuperAdmins are allowed to access CAP.
  return accessTokenPayload?.SuperAdmin === "true";
}

export async function login(force = false) {
  if ((await isAuthenticated()) && !force) return;

  await redirectToLogin();
}

export async function logout(): Promise<void> {
  localStorage.removeItem("auth");
}
