import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  HttpError,
  RaRecord,
} from "react-admin";
import baseDataProvider from "../dataProvider/baseDataProvider";
import { gql, postGraphql } from "../dataProvider/utils";

const diGrantsDataProvider: DataProvider = {
  ...baseDataProvider,

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    const { pagination, filter } = params;
    const response = await postGraphql(
      gql`
        query UADIGrantsGetList(
          $search: String
          $type: DIGrantType
          $page: Int
          $perPage: Int
        ) {
          diGrants(
            search: $search
            type: $type
            page: $page
            perPage: $perPage
          ) {
            pageInfo {
              totalCount
            }
            nodes {
              id
              name
              type
              createdAt
            }
          }
        }
      `,
      {
        page: pagination.page,
        perPage: pagination.perPage,
        search: filter.q || filter.search,
        type: filter.type,
      }
    );
    return {
      data: response.json.data.diGrants.nodes,
      total: response.json.data.diGrants.pageInfo.totalCount,
    };
  },

  async getOne<RecordType extends RaRecord = any>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const response = await postGraphql(
      gql`
        query UADIGrantGetOne($id: ID!) {
          diGrant(id: $id) {
            id
            name
            type
            createdAt
            users {
              pageInfo {
                totalCount
              }
              nodes {
                user {
                  id
                  username
                  givenName
                  familyName
                }
                createdAt
              }
            }
            practices {
              pageInfo {
                totalCount
              }
              nodes {
                practice {
                  id
                  customerNumber
                  name
                }
                createdAt
              }
            }
            practiceMemberships {
              pageInfo {
                totalCount
              }
            }
          }
        }
      `,
      { id: params.id }
    );
    const { diGrant } = response.json.data;
    if (!diGrant) {
      throw new HttpError("DI Grant not found", 404);
    }

    diGrant.users.nodes = diGrant.users.nodes
      .filter((userGrant: any) => userGrant.user != null)
      .map((userGrant: any) => ({
        id: userGrant.user.id.toLowerCase(),
        username: userGrant.user.username,
        givenName: userGrant.user.givenName,
        familyName: userGrant.user.familyName,
        createdAt: userGrant.createdAt,
      }));
    diGrant.practices.nodes = diGrant.practices.nodes
      .filter((practiceGrant: any) => practiceGrant.practice != null)
      .map((practiceGrant: any) => ({
        id: practiceGrant.practice.id,
        customerNumber: practiceGrant.practice.customerNumber,
        name: practiceGrant.practice.name,
        createdAt: practiceGrant.createdAt,
      }));
    return { data: diGrant };
  },
};

export default diGrantsDataProvider;
