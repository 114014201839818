import * as React from "react";
import { CreateProps, required, SimpleForm, TextInput } from "react-admin";
import CustomCreate from "../components/CustomCreate";

export default function OrganizationCreate(props: CreateProps) {
  return (
    <CustomCreate {...props}>
      <SimpleForm mode="onChange" reValidateMode="onChange">
        <TextInput source="name" validate={required()} />
        <TextInput source="crmAccountId" validate={required()} />
      </SimpleForm>
    </CustomCreate>
  );
}
