import { DataProvider, GetListResult } from "react-admin";
import { DILM_API_BASE_URL, DI_API_BASE_URL, LM_API_BASE_URL } from "../config";
import baseDataProvider from "../dataProvider/baseDataProvider";
import { getAuthHeaders } from "../dataProvider/utils";
import { AppHealth, AppHealthEndpoint, ServiceInfo } from "./types";

const appHealthEndpoints = [
  {
    appName: "lm-omcom",
    url: `${LM_API_BASE_URL}/omcom/health/`,
    isAuthRequired: false,
  },
  {
    appName: "lm-appointments",
    url: `${LM_API_BASE_URL}/appointments/health/`,
    isAuthRequired: false,
  },
  {
    appName: "lm-iam",
    url: `${LM_API_BASE_URL}/iam/health/`,
    isAuthRequired: false,
  },
  {
    appName: "lm-integrations",
    url: `${LM_API_BASE_URL}/integrations/health/`,
    isAuthRequired: false,
  },
  {
    appName: "dilm-hub",
    url: `${DILM_API_BASE_URL}/hub/health/`,
    isAuthRequired: false,
  },
  {
    appName: "dilm-graphql",
    url: `${DILM_API_BASE_URL}/graphql/health/`,
    isAuthRequired: false,
  },
  {
    appName: "lm-patients-web",
    url: `${LM_API_BASE_URL}/health/`,
    isAuthRequired: false,
  },
  {
    appName: "lm-graphql",
    url: `${LM_API_BASE_URL}/graphql/health/`,
    isAuthRequired: false,
  },
  {
    appName: "lm-clients",
    url: `${LM_API_BASE_URL}/clients/health/`,
    isAuthRequired: false,
  },
  {
    appName: "lm-notifications",
    url: `${LM_API_BASE_URL}/notifications/health/`,
    isAuthRequired: false,
  },
  {
    appName: "lm-openings",
    url: `${LM_API_BASE_URL}/openings/health/`,
    isAuthRequired: false,
  },
  {
    appName: "lm-widgets",
    url: `${LM_API_BASE_URL}/widgets/health/`,
    isAuthRequired: false,
  },
  {
    appName: "di-core-api",
    url: `${DI_API_BASE_URL}/Health/CheckHealth/`,
    isAuthRequired: true,
  },
];

const appHealthDataProvider: DataProvider = {
  ...baseDataProvider,
  async getList(): Promise<GetListResult<any>> {
    const authHeaders = await getAuthHeaders();
    return new Promise((resolve) => {
      const fetches = appHealthEndpoints.map((app: AppHealthEndpoint) => {
        // eslint-disable-next-line @typescript-eslint/ban-types
        const options: object = {
          headers: {
            accept: "application/json",
            ...(app.isAuthRequired ? authHeaders : {}),
          },
        };
        return fetch(app.url, options);
      });

      const getListResult = async () => {
        const fetchResults = await Promise.allSettled(fetches);
        const mapResults = fetchResults.map((result, index) => {
          const AppHealthObject = (
            services: ServiceInfo[],
            summary: string
          ): AppHealth => ({
            id: index,
            appName: appHealthEndpoints[index].appName,
            services,
            summary,
          });

          // guard for failed fetch (e.g. CORS error)
          if (result.status === "rejected") {
            return Promise.resolve(
              AppHealthObject([], `Error: ${result.reason.message}`)
            );
          }
          // guard for response not ok (e.g. 4xx or 5xx error)
          if (result.value.ok === false) {
            return Promise.resolve(
              AppHealthObject(
                [],
                `Error: ${result.value.status} ${result.value.statusText}`
              )
            );
          }

          const getAppHealth = async (): Promise<AppHealth> => {
            const jsonData = await result.value.json();
            // make services an array if not one already
            const services = Array.isArray(jsonData) ? jsonData : [jsonData];
            const summary = `
              ${services.filter((svc) => svc.result === true).length}
              of ${services.length} services healthy
            `;

            return AppHealthObject(services, summary);
          };
          return getAppHealth();
        });
        const appHealthChecks = await Promise.all(mapResults);

        return {
          data: appHealthChecks,
          total: appHealthChecks.length,
        };
      };

      return resolve(getListResult());
    });
  },
};

export default appHealthDataProvider;
