import { sortBy } from "lodash";
import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  HttpError,
  RaRecord,
} from "react-admin";
import baseDataProvider from "../dataProvider/baseDataProvider";
import { gql, postGraphql } from "../dataProvider/utils";
import { normalizePracticeGrantId } from "../diPracticeGrants";

const diPracticesDataProvider: DataProvider = {
  ...baseDataProvider,

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    const { pagination, filter } = params;
    const response = await postGraphql(
      gql`
        query UADIPracticesGetList($search: String, $page: Int, $perPage: Int) {
          diPractices(search: $search, page: $page, perPage: $perPage) {
            pageInfo {
              totalCount
            }
            nodes {
              id
              name
              customerNumber
              isActive
              pmsName
              lastSyncedAt
            }
          }
        }
      `,
      {
        page: pagination.page,
        perPage: pagination.perPage,
        search: filter.q || filter.search,
      }
    );
    return {
      data: response.json.data.diPractices.nodes,
      total: response.json.data.diPractices.pageInfo.totalCount,
    };
  },

  async getOne<RecordType extends RaRecord = any>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const response = await postGraphql(
      gql`
        query UADIPracticeGetOne($id: ID!) {
          diPractice(id: $id) {
            id
            customerNumber
            name
            phone
            email
            createdAt
            updatedAt
            firstSyncedAt
            lastSyncedAt
            isActive
            pmsName
            clinic {
              id
              name
            }
            featureFlags {
              id
              name
              practiceId
            }
            practiceMemberships(includeInactive: true) {
              isActive
              isPracticeAdmin
              user {
                id
                username
              }
            }
          }
        }
      `,
      { id: params.id }
    );
    const { diPractice } = response.json.data;
    if (!diPractice) {
      throw new HttpError("DI Practice not found", 404);
    }
    diPractice.featureFlags.forEach((featureFlag: any) =>
      normalizePracticeGrantId(featureFlag)
    );
    diPractice.users = sortBy(
      diPractice.practiceMemberships.map((membership: any) => ({
        id: membership.user.id.toLowerCase(),
        username: membership.user.username,
        isActive: membership.isActive,
        isPracticeAdmin: membership.isPracticeAdmin,
      })),
      "username"
    );
    return { data: diPractice };
  },
};

export default diPracticesDataProvider;
