import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  TextField,
  TextInput,
} from "react-admin";
import BooleanColorField from "../components/BooleanColorField";
import IdField from "../components/IdField";

export default function DiPracticeList(props: ListProps): JSX.Element {
  return (
    <List
      {...props}
      filters={[<TextInput label="Search" source="search" alwaysOn />]}
      perPage={25}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" sortable={false} />
        <IdField source="customerNumber" sortable={false} copyable={false} />
        <BooleanColorField source="isActive" />
        <TextField source="pmsName" sortable={false} emptyText="–" />
        <DateField source="lastSyncedAt" sortable={false} />
      </Datagrid>
    </List>
  );
}
