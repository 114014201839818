// eslint-disable-next-line
import { PropertyPath, update } from "lodash";
import { fetchUtils, HttpError } from "react-admin";
import { getAccessToken } from "../auth";
import { DILM_API_BASE_URL } from "../config";

// This doesn't actually do anything at runtime, but it allows you to tag
// queries with `gql`, which turns on syntax highlighting and prettier
// formatting for the GraphQL queries
export function gql(strings: TemplateStringsArray): string {
  return strings.join("");
}

export async function getAuthHeaders(): Promise<Record<string, string>> {
  const accessToken = await getAccessToken();
  if (!accessToken) throw new HttpError("Not logged in.", 401);

  return {
    Authorization: `Bearer ${accessToken}`,
  };
}

export type GraphQLVariables = Record<string, any>;

// TODO: type this
export type GraphQLError = any;

export type GraphQLResponse<T = any> = {
  status: number;
  headers: Headers;
  body: string;
  json: {
    data: T | undefined | null;
    errors: GraphQLError[] | undefined | null;
  };
};

export async function postGraphql<T = any>(
  query: string,
  variables: GraphQLVariables
): Promise<GraphQLResponse<T>> {
  const authHeaders = await getAuthHeaders();
  const response = await fetchUtils.fetchJson(`${DILM_API_BASE_URL}/graphql/`, {
    method: "POST",
    body: JSON.stringify({
      query,
      variables,
    }),
    headers: new Headers({ ...authHeaders }),
  });
  if (response.status < 200 || response.status >= 300) {
    throw new HttpError(
      response.body || "GraphQL query failed",
      response.status
    );
  }
  if (response.json.errors && response.json.errors.length > 0) {
    // eslint-disable-next-line no-console
    console.warn(response.json.errors);
    throw new HttpError(
      response.json.errors[0].message || "GraphQL query failed",
      response.status
    );
  }
  return response;
}

export async function postGraphqlMutation<T = any>(
  query: string,
  variables: GraphQLVariables,
  mutationKey = "mutation"
): Promise<GraphQLResponse<T>> {
  const response = await postGraphql(query, variables);
  const mutation = response.json?.data?.[mutationKey];
  if (mutation && mutation.status === "FAILED") {
    // eslint-disable-next-line no-console
    if (mutation.errors) console.warn(mutation.errors);
    throw new HttpError(
      mutation.errors?.[0]?.details?.[0]?.message || "GraphQL mutation failed",
      422
    );
  }
  return response;
}

// react-admin require the ids of references match exactly when loading
// "show" pages. This function helps us normalize case when guids
// occasionally return as uppercase or lowercase depending on the service.
//
// eslint-disable-next-line @typescript-eslint/ban-types
export function normalizeGuid(record: object, key: PropertyPath): object {
  return update(record, key, (value: string | undefined): string | undefined =>
    value?.toLowerCase()
  );
}
