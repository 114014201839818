import { Divider, Typography } from "@mui/material";
import * as React from "react";
import {
  DateField,
  ImageField,
  ShowProps,
  SimpleShowLayout,
  TextField,
  UrlField,
} from "react-admin";
import AsideContainer from "../components/AsideContainer";
import BooleanColorField from "../components/BooleanColorField";
import CustomShow from "../components/CustomShow";
import IdField from "../components/IdField";
import LinkReferenceField from "../components/LinkReferenceField";

function LmPracticeAside() {
  return (
    <AsideContainer>
      <SimpleShowLayout spacing={2}>
        <IdField source="id" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </SimpleShowLayout>
    </AsideContainer>
  );
}

export default function LmPracticeShow(props: ShowProps): JSX.Element {
  return (
    <CustomShow {...props} aside={<LmPracticeAside />}>
      <SimpleShowLayout spacing={2}>
        <ImageField source="logo" emptyText="–" />
        <TextField source="name" />
        <UrlField source="intakeFormUrl" emptyText="–" />
        <LinkReferenceField
          source="organization.id"
          reference="organizations"
          link="show"
        >
          <TextField source="organization.name" emptyText="–" />
        </LinkReferenceField>
        <Divider />
        <Typography variant="subtitle2">Features</Typography>
        <BooleanColorField source="onlineSchedulingEnabled" />
        <BooleanColorField source="whiteLabeledNotificationsEnabled" />
      </SimpleShowLayout>
    </CustomShow>
  );
}
