export enum PMSType {
  "ABELDent" = "ABEL_DENT",
  "Curve" = "CURVE",
  "Denticon" = "DENTICON",
  "Dentrix" = "DENTRIX",
  "Dentrix Enterprise" = "DENTRIX_ENTERPRISE",
  "Dolphin" = "DOLPHIN",
  "Eaglesoft" = "EAGLESOFT",
  "Easy Dental" = "EASY_DENTAL",
  "Fuse" = "FUSE",
  "Open Dental" = "OPEN_DENTAL",
  "Ortho Trac" = "ORTHO_TRAC",
  "SoftDent" = "SOFT_DENT",
  "Tracker" = "TRACKER",
  "Unknown" = "UNKNOWN",
}
export enum STATESMappings {
  "arizona" = "AZ",
  "alabama" = "AL",
  "alaska" = "AK",
  "arkansas" = "AR",
  "california" = "CA",
  "colorado" = "CO",
  "connecticut" = "CT",
  "delaware" = "DE",
  "district of columbia" = "DC",
  "florida" = "FL",
  "georgia" = "GA",
  "hawaii" = "HI",
  "idaho" = "ID",
  "illinois" = "IL",
  "indiana" = "IN",
  "iowa" = "IA",
  "kansas" = "KS",
  "kentucky" = "KY",
  "louisiana" = "LA",
  "maine" = "ME",
  "maryland" = "MD",
  "massachusetts" = "MA",
  "michigan" = "MI",
  "minnesota" = "MN",
  "mississippi" = "MS",
  "missouri" = "MO",
  "montana" = "MT",
  "nebraska" = "NE",
  "nevada" = "NV",
  "new hampshire" = "NH",
  "new jersey" = "NJ",
  "new mexico" = "NM",
  "new york" = "NY",
  "north carolina" = "NC",
  "north dakota" = "ND",
  "ohio" = "OH",
  "oklahoma" = "OK",
  "oregon" = "OR",
  "pennsylvania" = "PA",
  "rhode island" = "RI",
  "south carolina" = "SC",
  "south dakota" = "SD",
  "tennessee" = "TN",
  "texas" = "TX",
  "utah" = "UT",
  "vermont" = "VT",
  "virginia" = "VA",
  "washington" = "WA",
  "west virginia" = "WV",
  "wisconsin" = "WI",
  "wyoming" = "WY",
}

export const ClinicCustomerType = {
  SNAPSHOT: "SNAPSHOT",
  CUSTOMER: "CUSTOMER",
  DEMO: "DEMO",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ClinicCustomerType =
  typeof ClinicCustomerType[keyof typeof ClinicCustomerType];
