import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import * as React from "react";
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  TextField,
  TextInput,
  useCreatePath,
} from "react-admin";
import { Link } from "react-router-dom";
import BooleanColorField from "../components/BooleanColorField";
import IdField from "../components/IdField";
import { WonAccountGetListResultItem } from "./wonAccountsDataProvider";

export default function WonAccountsList(props: ListProps): JSX.Element {
  const createPath = useCreatePath();

  return (
    <List
      {...props}
      hasCreate={false}
      filters={[
        <TextInput
          label="Search"
          source="search"
          clearAlwaysVisible
          alwaysOn
        />,
      ]}
      pagination={false}
    >
      <Datagrid resource="wonAccounts" bulkActionButtons={false}>
        <TextField source="name" />
        <BooleanColorField source="isSnapshot" sortable={false} />
        <BooleanColorField source="hasActiveProducts" sortable={false} />
        <TextField source="practiceManagementSystem" sortable={false} />
        <TextField source="parent.name" />
        <IdField source="id" sortable={false} copyable={false} />
        <FunctionField
          render={(record: WonAccountGetListResultItem) => {
            const to =
              record.clinicId == null
                ? createPath({ resource: "clinics", type: "create" })
                : createPath({
                    resource: "clinics",
                    id: record.clinicId,
                    type: "edit",
                  });
            const state =
              record.clinicId == null
                ? { record: { salesforceId: record.id } }
                : {};
            return (
              <Button
                component={Link}
                to={to}
                state={state}
                size="small"
                startIcon={<Add />}
              />
            );
          }}
        />
      </Datagrid>
    </List>
  );
}
