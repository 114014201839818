// Simple Deferred implementation that allows you to resolve/reject a promise
// "from the outside".
//
// eslint-disable-next-line import/prefer-default-export
export class Deferred<T = any> {
  promise: Promise<T>;

  resolve: (value: T | PromiseLike<T>) => void = () => {};

  reject: (reason: any) => void = () => {};

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
