import { Edit } from "@mui/icons-material";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import {
  DateField,
  Labeled,
  SelectField,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import AsideContainer from "../components/AsideContainer";
import CustomShow from "../components/CustomShow";
import ExternalLinkReferenceField from "../components/ExternalLinkReferenceField";
import IdField from "../components/IdField";
import LinkReferenceField from "../components/LinkReferenceField";
import { clinicCustomerTypeOptions } from "./constants";
import { PMSType } from "./type";
import { PMS_LIST } from "./utils";

function ClinicAside() {
  return (
    <AsideContainer>
      <SimpleShowLayout spacing={2}>
        <IdField source="id" />
        <IdField source="customerNumber" />
        <IdField source="guid" />
        <IdField source="guidAlias" />
        <DateField source="createdAt" showTime />
        <LinkReferenceField source="createdBy.id" reference="users" link="show">
          <TextField source="createdBy.username" emptyText="–" />
        </LinkReferenceField>
        <DateField source="updatedAt" showTime />
        <LinkReferenceField source="updatedBy.id" reference="users" link="show">
          <TextField source="updatedBy.username" emptyText="–" />
        </LinkReferenceField>
      </SimpleShowLayout>
    </AsideContainer>
  );
}

function AdminLinkContent() {
  return (
    <Typography variant="caption">
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <span>CAP Admin</span>
      </Stack>
    </Typography>
  );
}

function PMSTypeField() {
  const record = useRecordContext();
  const reference =
    record.practiceManagementSystemType == null ||
    record.practiceManagementSystemType === PMSType.Unknown
      ? "clinics"
      : "clinicPmsTypes";
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Labeled source="practiceManagementSystemType" sx={{ flex: 1 }}>
        <SelectField
          choices={[...PMS_LIST]}
          source="practiceManagementSystemType"
          optionValue="value"
          optionText="label"
          emptyText="–"
        />
      </Labeled>
      <LinkReferenceField
        source="id"
        reference={reference}
        link="edit"
        variant="caption"
      >
        <Edit fontSize="inherit" /> <span>Change PMS</span>
      </LinkReferenceField>
    </Stack>
  );
}

export default function ClinicShow(props: ShowProps): JSX.Element {
  return (
    <CustomShow {...props} aside={<ClinicAside />}>
      <SimpleShowLayout spacing={2}>
        <TextField source="name" />
        <TextField source="internalName" emptyText="–" />
        <LinkReferenceField
          source="organization.id"
          reference="organizations"
          link="show"
        >
          <TextField source="organization.name" emptyText="–" />
        </LinkReferenceField>
        <Stack direction="row" spacing={2} alignItems="center">
          <Labeled source="diPractice" sx={{ flex: 1 }}>
            <LinkReferenceField
              source="diPracticeId"
              reference="diPractices"
              link="show"
            >
              <AdminLinkContent />
            </LinkReferenceField>
          </Labeled>
          <LinkReferenceField
            source="id"
            reference="clinicDiPracticeMappings"
            link="edit"
            variant="caption"
          >
            <Edit fontSize="inherit" /> <span>Change Analytics Clinic</span>
          </LinkReferenceField>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Labeled source="mdPractice" sx={{ flex: 1 }}>
            <Stack spacing={0.5}>
              <ExternalLinkReferenceField
                reference="mdPractices"
                source="mdPracticeId"
              />
              <ExternalLinkReferenceField
                reference="mdDashboardPractices"
                source="guid"
                emptyContent={null}
              />
            </Stack>
          </Labeled>
          <LinkReferenceField
            source="id"
            reference="clinicMdPracticeMappings"
            link="edit"
            variant="caption"
          >
            <Edit fontSize="inherit" /> <span>Change Engagement Clinic</span>
          </LinkReferenceField>
        </Stack>
        <Labeled source="lmOffice">
          <Stack spacing={0.5}>
            <LinkReferenceField
              source="lmOffice.id"
              reference="lmOffices"
              link="show"
            >
              <AdminLinkContent />
            </LinkReferenceField>
            <ExternalLinkReferenceField
              reference="lmOffices"
              source="lmOffice.id"
              emptyContent={null}
            />
          </Stack>
        </Labeled>
        <Labeled source="crmAccountId">
          <Box>
            <IdField source="crmAccountId" />
            <ExternalLinkReferenceField
              reference="salesforceAccounts"
              source="crmAccountId"
            />
          </Box>
        </Labeled>
        <Divider />
        <Typography variant="subtitle2">Info</Typography>
        <SelectField
          choices={clinicCustomerTypeOptions}
          source="customerType"
          emptyText="–"
        />
        <PMSTypeField />
        <TextField source="phone" emptyText="–" />
        <TextField source="email" emptyText="–" />
        <TextField source="website" emptyText="–" />
        <Divider />
        <Typography variant="subtitle2">Address</Typography>
        <TextField source="address1" emptyText="–" />
        <TextField source="address2" emptyText="–" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Labeled source="city">
              <TextField source="city" emptyText="–" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled source="state">
              <TextField source="state" emptyText="–" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled source="zip">
              <TextField source="zip" emptyText="–" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled source="country">
              <TextField source="country" emptyText="–" />
            </Labeled>
          </Grid>
        </Grid>
        <TextField source="timezone" emptyText="–" />
      </SimpleShowLayout>
    </CustomShow>
  );
}
