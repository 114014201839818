import * as React from "react";
import { BooleanField, BooleanFieldProps } from "react-admin";

export default function BooleanColorField(props: BooleanFieldProps) {
  return (
    <BooleanField
      {...props}
      sx={{
        '& [data-testid="true"]': {
          color: "success.light",
        },
        '& [data-testid="false"]': {
          color: "error.light",
        },
      }}
    />
  );
}
