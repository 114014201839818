import {
  DataProvider,
  GetOneParams,
  GetOneResult,
  HttpError,
} from "react-admin";
import baseDataProvider from "../dataProvider/baseDataProvider";
import { gql, postGraphql } from "../dataProvider/utils";

const lmPracticesDataProvider: DataProvider = {
  ...baseDataProvider,

  async getOne(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<any>> {
    const response = await postGraphql(
      gql`
        query UALmPracticeGetOne($id: ID!) {
          lmPractice(id: $id) {
            id
            name
            logo(size: 50)
            intakeFormUrl
            onlineSchedulingEnabled
            whiteLabeledNotificationsEnabled
            createdAt
            updatedAt
            organization {
              id
              name
            }
          }
        }
      `,
      { id: params.id }
    );
    if (!response.json.data.lmPractice) {
      throw new HttpError("Practice not found", 404);
    }
    return { data: response.json.data.lmPractice };
  },
};

export default lmPracticesDataProvider;
