import * as React from "react";
import {
  ChipField,
  Datagrid,
  DateField,
  List,
  ListProps,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import IdField from "../components/IdField";

export default function DiGrantList(props: ListProps): JSX.Element {
  return (
    <List
      {...props}
      filters={[
        <TextInput label="Search" source="search" alwaysOn />,
        <SelectInput
          label="Type"
          source="type"
          choices={[
            { id: "ENTERPRISE", name: "Enterprise" },
            { id: "FEATURE_FLAG", name: "Feature Flag" },
            { id: "PERMISSION", name: "Permission" },
            { id: "SETTING", name: "Setting" },
          ]}
          alwaysOn
        />,
      ]}
      perPage={25}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <IdField source="id" sortable={false} copyable={false} />
        <TextField source="name" sortable={false} />
        <ChipField source="type" />
        <DateField source="createdAt" sortable={false} />
      </Datagrid>
    </List>
  );
}
