const BASE_64_CHARS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

// Using btoa on the hex string of the SHA-256 hash does not work for the
// PKCE code challenge. So we must Base64 encode the array buffer directly.
// This is pulled from base64-arraybuffer: https://github.com/niklasvh/base64-arraybuffer
function base64Encode(arraybuffer: ArrayBuffer) {
  const bytes = new Uint8Array(arraybuffer);
  let i;
  const len = bytes.length;
  let base64 = "";

  for (i = 0; i < len; i += 3) {
    // eslint-disable-next-line no-bitwise
    base64 += BASE_64_CHARS[bytes[i] >> 2];
    // eslint-disable-next-line no-bitwise
    base64 += BASE_64_CHARS[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
    // eslint-disable-next-line no-bitwise
    base64 += BASE_64_CHARS[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
    // eslint-disable-next-line no-bitwise
    base64 += BASE_64_CHARS[bytes[i + 2] & 63];
  }

  if (len % 3 === 2) {
    base64 = `${base64.substring(0, base64.length - 1)}=`;
  } else if (len % 3 === 1) {
    base64 = `${base64.substring(0, base64.length - 2)}==`;
  }

  return base64;
}

function toHexString(hashArray: Uint8Array) {
  return Array.from(hashArray)
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

function sha256(message: string) {
  const messageBuffer = new TextEncoder().encode(message);
  return crypto.subtle.digest("SHA-256", messageBuffer);
}

export function createCodeVerifier(length = 24): string {
  return toHexString(crypto.getRandomValues(new Uint8Array(length)));
}

export async function createCodeChallenge(verifier: string): Promise<string> {
  const digest = await sha256(verifier);
  return base64Encode(digest)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}
