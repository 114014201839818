import { LinkProps } from "@mui/material";
import { get } from "lodash";
import * as React from "react";
import {
  Link,
  PublicFieldProps,
  useCreatePath,
  useRecordContext,
} from "react-admin";

export interface LinkReferenceFieldProps
  extends PublicFieldProps,
    Pick<LinkProps, "variant"> {
  source: string;
  reference: string;
  link?: "edit" | "show";
  children: React.ReactNode;
  emptyContent?: React.ReactNode;
}

/**
 * The default Reference field looks up the reference through the data provider,
 * which is unnecessary in GraphQL...so this field merely wraps its children
 * with a <Link> based on the source id.
 */
export default function LinkReferenceField({
  children,
  source,
  reference,
  link,
  variant,
  emptyContent,
  ...props
}: LinkReferenceFieldProps) {
  // const resource = useResourceContext();
  const record = useRecordContext(props);
  const createPath = useCreatePath();

  const id = get(record, source);
  if (!id) {
    return typeof emptyContent === "undefined" ? (
      <span>–</span>
    ) : (
      <>{emptyContent}</>
    );
  }
  const resourceLinkPath = createPath({
    resource: reference,
    id,
    type: link || "show",
  });
  return (
    <Link
      to={resourceLinkPath}
      onClick={(
        event:
          | React.MouseEvent<HTMLAnchorElement, MouseEvent>
          | React.MouseEvent<HTMLSpanElement, MouseEvent>
      ) => {
        // useful to prevent click bubbling in a datagrid with rowClick
        event.stopPropagation();
      }}
      variant={variant}
    >
      {children}
    </Link>
  );
}

LinkReferenceField.defaultProps = {
  link: "show",
};
