import { Box } from "@mui/material";
import * as React from "react";

export default function DiLoadingIndicator() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src="https://distaticassets.azureedge.net/images/logo-animation-dark-transparent.gif"
        alt="Loading…"
        width="100"
      />
    </Box>
  );
}
