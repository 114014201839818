import { createTheme } from "@mui/material";
import { defaultTheme } from "react-admin";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    code: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    code?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    code: true;
  }
}

const theme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#0060c6",
    },
    secondary: {
      main: "#0060c6",
    },
  },
  typography: {
    ...defaultTheme.typography,
    code: {
      fontFamily: "'Roboto Mono', monospace",
      fontSize: "0.8rem",
    },
  },
});

export default theme;
