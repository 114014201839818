import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Typography } from "@mui/material";
import * as React from "react";
import {
  useGetRecordRepresentation,
  useGetResourceLabel,
  useRecordContext,
  useResourceContext,
} from "react-admin";

interface RecordTitleProps {
  resource?: string;
}

export default function RecordTitle({ ...props }: RecordTitleProps) {
  const resource = useResourceContext(props);
  const getRecordRepresentation = useGetRecordRepresentation(resource);
  const getResourceLabel = useGetResourceLabel();
  const record = useRecordContext();
  return (
    <>
      <Typography variant="overline" component="span">
        {getResourceLabel(resource)} <NavigateNextIcon fontSize="inherit" />
      </Typography>{" "}
      {getRecordRepresentation(record)}
    </>
  );
}

RecordTitle.defaultProps = {
  resource: undefined,
};
