declare const CONFIG: Record<string, string>;

// Checks if the value has not been interpolated via CI, i.e.
// "#{DILM_API_BASE_URL}#".
function ensureInterpolated(value: string | undefined): string | undefined {
  if (!value || value.match(/^#\{.*\}#$/)) {
    return undefined;
  }
  return value;
}

export const LM_API_BASE_URL =
  ensureInterpolated(CONFIG.LM_API_BASE_URL) ||
  process.env.REACT_APP_LM_API_BASE_URL ||
  "https://development.localmed.com";

export const DI_API_BASE_URL =
  ensureInterpolated(CONFIG.DI_API_BASE_URL) ||
  process.env.REACT_APP_DI_API_BASE_URL ||
  "https://api.dev.dentalintel.com/api";

export const MD_ADMIN_BASE_URL =
  ensureInterpolated(CONFIG.MD_ADMIN_BASE_URL) ||
  process.env.REACT_APP_MD_ADMIN_BASE_URL ||
  "https://dev.modento.io";

export const MD_DASHBOARD_BASE_URL =
  ensureInterpolated(CONFIG.MD_DASHBOARD_BASE_URL) ||
  process.env.REACT_APP_MD_DASHBOARD_BASE_URL ||
  "https://dev-office.modento.io";

export const DILM_API_BASE_URL =
  ensureInterpolated(CONFIG.DILM_API_BASE_URL) ||
  process.env.REACT_APP_DILM_API_BASE_URL ||
  "https://dilm-dev.dentalintel.com";

export const IDP_BASE_URL =
  ensureInterpolated(CONFIG.IDP_BASE_URL) ||
  process.env.REACT_APP_IDP_BASE_URL ||
  "https://idp-dev.dentalintel.com";

export const OAUTH_REDIRECT_URI =
  ensureInterpolated(CONFIG.OAUTH_REDIRECT_URI) ||
  process.env.REACT_APP_OAUTH_REDIRECT_URI ||
  "http://localhost:3000/authorized";

export const OAUTH_CLIENT_ID =
  ensureInterpolated(CONFIG.OAUTH_CLIENT_ID) ||
  process.env.REACT_APP_OAUTH_CLIENT_ID ||
  "diuberadmin";

export const SALESFORCE_BASE_URL =
  ensureInterpolated(CONFIG.SALESFORCE_BASE_URL) ||
  process.env.REACT_APP_SALESFORCE_BASE_URL ||
  "https://dentalintel--sandboxsha.my.salesforce.com";
