import { Typography } from "@mui/material";
import * as React from "react";
import {
  ArrayField,
  Datagrid,
  DatagridProps,
  PublicFieldProps,
  ResourceContextProvider,
} from "react-admin";

export interface ReferenceListFieldProps
  extends PublicFieldProps,
    Pick<DatagridProps, "rowClick"> {
  reference: string;
  children: React.ReactNode;
}

export default function ReferenceListField(props: ReferenceListFieldProps) {
  const { source, reference, rowClick, children } = props;
  return (
    <ResourceContextProvider value={reference}>
      <ArrayField source={source}>
        <Datagrid
          empty={<Typography variant="body1">–</Typography>}
          rowClick={rowClick ?? "show"}
          bulkActionButtons={false}
          sx={{
            border: "1px solid",
            borderColor: "grey.200",
            borderRadius: 1,
          }}
        >
          {children}
        </Datagrid>
      </ArrayField>
    </ResourceContextProvider>
  );
}
