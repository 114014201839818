import DefaultIcon from "@mui/icons-material/ViewList";
import { alpha, styled } from "@mui/material";
import {
  useCreatePath,
  useGetResourceLabel,
  useResourceDefinitions,
} from "ra-core";
import * as React from "react";
import { MenuItemLink, MenuItemLinkClasses } from "react-admin";
import { useMatch } from "react-router";

const StyledMenuItemLink = styled(MenuItemLink)(({ theme }) => ({
  [`&.${MenuItemLinkClasses.active}`]: {
    backgroundColor: alpha(theme.palette.info.main, 0.1),
    color: theme.palette.primary.dark,
    fontWeight: "bold",
  },
  [`& .${MenuItemLinkClasses.icon}`]: {
    color: "inherit",
  },
}));

interface ResourceMenuItemProps {
  name: string;
  filterDefaultValues?: Record<string, unknown>;
}

// This is essentially copy/pasted from the react-admin project so we can
// customize the `to` prop reset the filters each time.
export default function ResourceMenuItem({
  name,
  filterDefaultValues = {},
}: ResourceMenuItemProps) {
  const resources = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const path = createPath({
    resource: name,
    type: "list",
  });
  // We have to do the matching _before_ we append the filter param, or else
  // the menu items will never match.
  const match = useMatch(path);
  // See: https://marmelab.com/react-admin/Menu.html#resetting-filters-on-menu-click
  const link = `${path}?filter=${JSON.stringify(filterDefaultValues)}`;
  if (!resources || !resources[name]) return null;
  return (
    <StyledMenuItemLink
      to={link}
      state={{ _scrollToTop: true }}
      primaryText={<>{getResourceLabel(name, 2)}</>}
      className={match != null ? MenuItemLinkClasses.active : undefined}
      leftIcon={
        resources[name].icon ? (
          React.createElement(resources[name].icon, { color: "inherit" })
        ) : (
          <DefaultIcon color="inherit" />
        )
      }
    />
  );
}
