import { Box } from "@mui/material";
import * as React from "react";
import DiLoadingIndicator from "../components/DiLoadingIndicator";
import { login } from "./idp";

export default function LoginPage() {
  React.useEffect(() => {
    login();
  }, []);
  return (
    // This page is flashed loading redirecting to the IDP Server for login,
    // so we show a loading indicator to make that less jarring.
    <Box
      sx={{
        position: "absolute",
        inset: "0",
        backgroundColor: "#f7f7f7",
      }}
    >
      <DiLoadingIndicator />
    </Box>
  );
}
