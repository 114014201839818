import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteParams,
  DeleteResult,
  GetOneParams,
  GetOneResult,
} from "ra-core";
import baseDataProvider from "../dataProvider/baseDataProvider";
import {
  gql,
  normalizeGuid,
  postGraphql,
  postGraphqlMutation,
} from "../dataProvider/utils";
import { getPracticeGrantIds, normalizePracticeGrantId } from "./utils";

const diPracticeGrantsDataProvider: DataProvider = {
  ...baseDataProvider,

  async getOne(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<any>> {
    const [practiceId, grantId] = getPracticeGrantIds(params.id);
    const response = await postGraphql(
      gql`
        query UADIPracticeGrantGetOne($practiceId: ID!, $grantId: ID!) {
          diPracticeGrant(practiceId: $practiceId, grantId: $grantId) {
            id
            name
            type
            createdAt
            createdBy {
              id
              username
            }
            practice {
              id
              name
            }
          }
        }
      `,
      { practiceId, grantId }
    );
    const { diPracticeGrant } = response.json.data;
    normalizePracticeGrantId(diPracticeGrant);
    normalizeGuid(diPracticeGrant, "createdBy.id");
    return { data: diPracticeGrant };
  },

  async create(
    resource: string,
    params: CreateParams
  ): Promise<CreateResult<any>> {
    const { data } = params;
    const response = await postGraphqlMutation(
      gql`
        mutation UADIPracticeGrantCreate($input: AddPracticeGrantInput) {
          addPracticeGrant(input: $input) {
            status
            errors {
              key
              details {
                code
                message
              }
            }
            payload {
              id
              practiceId
            }
          }
        }
      `,
      {
        input: {
          practiceId: data.practiceId,
          grantId: data.grantId,
        },
      },
      "addPracticeGrant"
    );
    const { payload } = response.json.data.addPracticeGrant;
    normalizePracticeGrantId(payload);
    return {
      data: { ...data, ...payload },
    };
  },

  async delete(
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult<any>> {
    const [practiceId, grantId] = getPracticeGrantIds(params.id.toString());
    await postGraphqlMutation(
      gql`
        mutation UADIPracticeGrantDelete($input: RemovePracticeGrantInput) {
          removePracticeGrant(input: $input) {
            status
            errors {
              key
              details {
                code
                message
              }
            }
          }
        }
      `,
      {
        input: { practiceId, grantId },
      },
      "removePracticeGrant"
    );
    // TODO: Note sure what to return here...
    return { data: {} };
  },
};

export default diPracticeGrantsDataProvider;
