import * as React from "react";
import {
  EditProps,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import CustomEdit from "../components/CustomEdit";

export default function OrganizationEdit(props: EditProps) {
  return (
    <CustomEdit {...props}>
      <SimpleForm
        mode="onChange"
        reValidateMode="onChange"
        toolbar={
          <Toolbar>
            <SaveButton alwaysEnable />
          </Toolbar>
        }
      >
        <TextInput source="name" validate={required()} />
      </SimpleForm>
    </CustomEdit>
  );
}
