import {
  CreateResult,
  DataProvider,
  DeleteManyResult,
  DeleteResult,
  GetListResult,
  GetManyParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneResult,
  RaRecord,
  UpdateManyResult,
  UpdateResult,
} from "react-admin";

const baseDataProvider: DataProvider = {
  async getList<RecordType extends RaRecord = any>(
    resource: string
  ): Promise<GetListResult<RecordType>> {
    throw new Error(`\`getList\` not implemented for resource: "${resource}"`);
  },

  async getOne<RecordType extends RaRecord = any>(
    resource: string
  ): Promise<GetOneResult<RecordType>> {
    throw new Error(`\`getOne\` not implemented for resource: "${resource}"`);
  },

  async getMany<RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    const { ids } = params;
    const values = await Promise.all(
      ids.map((id) => this.getOne(resource, { id }))
    );

    return {
      data: values.map(({ data }) => {
        return { ...data };
      }),
    };
  },

  async getManyReference<RecordType extends RaRecord = any>(
    resource: string
  ): Promise<GetManyReferenceResult<RecordType>> {
    throw new Error(
      `\`getManyReference\` not implemented for resource: "${resource}"`
    );
  },

  async update<RecordType extends RaRecord = any>(
    resource: string
  ): Promise<UpdateResult<RecordType>> {
    throw new Error(`\`update\` not implemented for resource: "${resource}"`);
  },

  async updateMany(resource: string): Promise<UpdateManyResult> {
    throw new Error(
      `\`updateMany\` not implemented for resource: "${resource}"`
    );
  },

  async create<RecordType extends RaRecord = any>(
    resource: string
  ): Promise<CreateResult<RecordType>> {
    throw new Error(`\`create\` not implemented for resource: "${resource}"`);
  },

  async delete<RecordType extends RaRecord = any>(
    resource: string
  ): Promise<DeleteResult<RecordType>> {
    throw new Error(`\`delete\` not implemented for resource: "${resource}"`);
  },

  async deleteMany(resource: string): Promise<DeleteManyResult> {
    throw new Error(
      `\`deleteMany\` not implemented for resource: "${resource}"`
    );
  },
};

export default baseDataProvider;
