import {
  DataProvider,
  GetOneParams,
  GetOneResult,
  HttpError,
  RaRecord,
} from "react-admin";
import baseDataProvider from "../dataProvider/baseDataProvider";
import { gql, postGraphql } from "../dataProvider/utils";

const lmOfficesDataProvider: DataProvider = {
  ...baseDataProvider,

  async getOne<RecordType extends RaRecord = any>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {
    const response = await postGraphql(
      gql`
        query UALmOfficeGetOne($id: ID!) {
          lmOffice(id: $id) {
            id
            name
            display
            messagingName
            address1
            address2
            city
            stateCode
            postalCode
            countryCode
            timeZoneCode
            email
            phone
            announcementEnabled
            appointmentRemindersEnabled
            appointmentReschedulingEnabled
            insuranceCoverageEnabled
            onlineSchedulingEnabled
            patientScreenerEnabled
            lmPractice {
              id
              name
            }
            clinic {
              id
              name
            }
          }
        }
      `,
      { id: params.id }
    );
    if (!response.json.data.lmOffice) {
      throw new HttpError("LM Office not found", 404);
    }
    return { data: response.json.data.lmOffice };
  },
};

export default lmOfficesDataProvider;
