import { Alert, AlertTitle, Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import {
  EditProps,
  SaveButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  Toolbar,
  TopToolbar,
  required,
  useCreatePath,
} from "react-admin";
import CustomEdit from "../components/CustomEdit";
import { PMS_LIST } from "./utils";

function ClinicPMSTypeEditActions() {
  return (
    <TopToolbar>
      <ShowButton resource="clinics" />
    </TopToolbar>
  );
}

export default function ClinicPMSTypeEdit(props: EditProps): JSX.Element {
  const createPath = useCreatePath();
  return (
    <CustomEdit
      {...props}
      resource="clinicPmsTypes"
      redirect={(_, id) => {
        const path = createPath({ resource: "clinics", id, type: "show" });
        return { pathname: path };
      }}
      actions={<ClinicPMSTypeEditActions />}
    >
      <SimpleForm
        mode="onChange"
        reValidateMode="onChange"
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <Box
          display="flex"
          sx={(theme) => ({
            alignItems: "flex-start",
            flexDirection: "column",
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
            },
          })}
        >
          <Alert
            severity="warning"
            sx={(theme) => ({
              marginBottom: 3,
              [theme.breakpoints.up("md")]: {
                marginBottom: 0,
                marginLeft: 3,
                order: 2,
                flex: 1,
              },
            })}
          >
            <AlertTitle>Proceed with Caution</AlertTitle>
            <Stack spacing={1} component="ul">
              <Typography component="li">
                Make sure you have followed the{" "}
                <a
                  href="https://den.tl/change-pms-sop"
                  target="_blank"
                  rel="noreferrer"
                >
                  preparation steps for changing a PMS
                </a>
                .
              </Typography>
              <Typography component="li">
                This will create a new Analytics Clinic with a new Customer
                Number.
              </Typography>
            </Stack>
          </Alert>
          <Stack
            spacing={3}
            width="100%"
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: {
                flex: 1,
              },
            })}
          >
            <SelectInput
              choices={[...PMS_LIST]}
              source="practiceManagementSystemType"
              optionValue="value"
              optionText="label"
              validate={required()}
            />
          </Stack>
        </Box>
      </SimpleForm>
    </CustomEdit>
  );
}
